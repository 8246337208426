import { Button } from "@mui/material"
import { useSearchParams } from "react-router-dom"
import { useEffect } from "react"
import axios from "axios"
import { POLL_RETRIEVE_API_URL, QUESTIONS_UPDATE_API_URL } from "../api/regulations"
import { fetchData } from "../store/slices/regulationsSlice"
import { useDispatch, useSelector } from "react-redux"
import { fetchPollData, fetchQuestions } from "../store/selectors"
import { Question } from "../components/Poll/Question"
import { createObject } from "../store/actions"
import { AuthCheck } from "../components/AuthCheck"
import { InputTypeCard } from "../components/Poll/InputTypeCard"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import Cookies from "js-cookie"
import { NotificationManager } from "react-notifications"

const QuestionsContainer = ({ items }) => {
  return (
    <div className={"min-h-full"}>
      <Droppable droppableId="questions">
        {(provided) => {
          return (
            <div
              className={
                "m-6 min-h-max rounded-lg border-4 border-dashed border-slate-200 pt-2 pb-[7rem]"
              }
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <div className="flex flex-col space-y-2">
                {[...items]
                  .sort((a, b) => a.order - b.order)
                  .map((item, index) => (
                    <Question key={item.id} itemObj={item} index={index} />
                  ))}
                {provided.placeholder}
              </div>
            </div>
          )
        }}
      </Droppable>
    </div>
  )
}

const PollBody = ({ pollData }) => {
  let dispatch = useDispatch()
  let items = useSelector(fetchQuestions)
  let inputTypes = [
    ["text", "Текст"],
    ["select", "Селект"],
    ["slider", "Слайдер"],
    ["addRequirement", "Предложить требование"],
    ["rating", "Рейтинг"],
    ["description", "Описание"],
  ]

  const handleDragEnd = (result) => {
    const { destination, source, draggableId } = result

    if (!destination) {
      return
    }

    if (destination.index === source.droppableId && destination.index === source.index) {
      return
    }
    if (source.droppableId === "questions" && destination.droppableId === "questions") {
      const item = items[source.index]
      let newItemIds = Array.from(items)
      newItemIds.splice(source.index, 1)
      newItemIds.splice(destination.index, 0, item)
      newItemIds = newItemIds.map((item, index) => {
        let newItem = { ...item }
        newItem.order = index
        return newItem
      })
      axios
        .put(QUESTIONS_UPDATE_API_URL(pollData.id), newItemIds[destination.index], {
          headers: { Authorization: `Token ${Cookies.get("access_token")}` },
        })
        .then()
      dispatch(fetchData({ data: { questions: [...newItemIds] } }))
    } else if (source.droppableId === "inputTypes" && destination.droppableId === "questions") {
      let newObj = {
        name: "Новый вопрос",
        type: draggableId,
        fields_settings: {},
        poll: pollData.id,
        order: destination.index,
      }
      createObject(dispatch, "questions", newObj)
    }
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <section
        aria-labelledby="primary-heading"
        className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last"
      >
        {pollData.id ? <QuestionsContainer items={items} pollData={pollData} /> : null}
      </section>

      <aside className="hidden md:order-1 md:block md:flex-shrink-0">
        <div className="border-r-1 relative flex h-full w-96 flex-col overflow-y-auto border-0 border-r-2 border-solid border-slate-300 p-2">
          <Droppable mb={2} droppableId="inputTypes">
            {(provided) => {
              return (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {inputTypes.map((item, index) => {
                    return (
                      <Draggable draggableId={item[0]} index={index} key={index}>
                        {(provided) => {
                          return <InputTypeCard provided={provided} type={item[0]} text={item[1]} />
                        }}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )
            }}
          </Droppable>
          <Button
            className="mt-4 hover:motion-reduce:animate-pulse"
            variant="contained"
            onClick={() =>
              axios
                .put(QUESTIONS_UPDATE_API_URL(pollData.id), items, {
                  headers: { Authorization: `Token ${Cookies.get("access_token")}` },
                })
                .then((r) => NotificationManager.success("Сохранена", "ФОРМА ОПРОСА"))
            }
          >
            Сохранить форму
          </Button>
        </div>
      </aside>
    </DragDropContext>
  )
}

export const PollRedactor = () => {
  let dispatch = useDispatch()
  const [regId, setRegId] = useSearchParams()
  useEffect(() => {
    axios
      .get(POLL_RETRIEVE_API_URL(regId.get("regId")))
      .then((r) => dispatch(fetchData({ data: r.data })))
  }, [dispatch, regId])
  let pollData = useSelector(fetchPollData)

  return (
    <>
      <AuthCheck />
      {pollData && <PollBody pollData={pollData} />}
    </>
  )
}
