import axios from "axios"
import Cookies from "js-cookie"
import { useEffect, useState } from "react"
import { Button } from "@mui/material"
import { POLL_RETRIEVE_API_URL, SUBMIT_ANSWERS_API_URL } from "../api/regulations"
import { useNavigate, useSearchParams } from "react-router-dom"
import { CompiledInput } from "../components/Poll/CompiledInput"
import { NotificationManager } from "react-notifications"

export const Poll = () => {
  let navigate = useNavigate()
  const [regId, setRegId] = useSearchParams()
  const [pollData, setPollData] = useState({})
  const [formInput, setFormInput] = useState({})
  const [errorMessage, setErrorMessage] = useState(undefined)

  const handleChange = (e, questionId) => {
    let newObj = { ...formInput }
    newObj[questionId] = e.target.value
    setFormInput(newObj)
  }

  const handleChangeArrayInput = (questionId, arr) => {
    let newObj = { ...formInput }
    newObj[questionId] = arr
    setFormInput(newObj)
  }

  useEffect(() => {
    axios
      .get(POLL_RETRIEVE_API_URL(regId.get("regId")), {
        headers: { Authorization: `Token ${Cookies.get("access_token")}` },
      })
      .then(
        (r) => {
          setPollData(r.data)
        },
        (error) => {
          if (error.response.status === 404) {
            setErrorMessage("Опросника к заданному нормативному акту не найдено")
          }
        }
      )
  }, [regId])

  if (pollData.poll) {
    return (
      <section
        aria-labelledby="primary-heading"
        className="m-auto flex h-full min-w-0 flex-1 flex-col overflow-y-auto px-4 sm:max-w-4xl lg:order-last"
      >
        {/*<h4 id="primary-heading">{pollData.poll.name}</h4>*/}
        <div className="flex flex-col space-y-4 ">
          {pollData.questions.map((item, index) => {
            return (
              <CompiledInput
                key={index}
                settings={item}
                onChange={handleChange}
                handleChangeArrayInput={handleChangeArrayInput}
              />
            )
          })}
          <Button
            variant="outlined"
            onClick={() => {
              axios
                .post(
                  SUBMIT_ANSWERS_API_URL,
                  { pollId: pollData.poll.id, ...formInput },
                  { headers: { Authorization: `Token ${Cookies.get("access_token")}` } }
                )
                .then(
                  (r) => {
                    if (r.status === 201) {
                      navigate("/form-success")
                    }
                  },
                  (error) => {
                    NotificationManager.error(JSON.stringify(error), "Произошла ошибка", 10000)
                  }
                )
            }}
          >
            Отправить
          </Button>
        </div>
      </section>
    )
  } else {
    return <div className="h4">{!errorMessage ? <>"Загрузка"</> : <>{errorMessage}</>}</div>
  }
}
