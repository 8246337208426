import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useState } from "react"
import palette from "../../theme/palette"
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline"

export const AddRequirement = ({ settings, handleChangeArrayInput }) => {
  const [items, setItems] = useState([])
  const [isOpen, setOpen] = useState(false)
  const [formInput, setFormInput] = useState({})
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"))
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleChangeArrayInputFunc = handleChangeArrayInput ? handleChangeArrayInput : (items) => {}

  const handleInputChange = (e) => {
    let newObj = { ...formInput }
    newObj[e.target.name] = e.target.value
    setFormInput(newObj)
  }
  const handleAddRequirement = () => {
    if (formInput.index === -1) {
      setItems((s) => {
        delete formInput.index
        let newState = [formInput, ...s]
        handleChangeArrayInputFunc(settings.id.toString(), newState)
        return newState
      })
    } else {
      setItems((s) => {
        let newState = [...s]
        newState[formInput.index] = formInput
        delete formInput.index
        handleChangeArrayInputFunc(settings.id.toString(), newState)
        return newState
      })
    }
  }

  return (
    <>
      <Stack>
        <div className="h5 pb-4">{settings.name}</div>
        <Button
          variant="contained"
          onClick={() => {
            setFormInput({ index: -1 })
            handleOpen()
          }}
        >
          Предложить требование
        </Button>
        <Stack spacing={2} mt={1}>
          {items.map((item, index) => (
            <Grid container key={index}>
              <Grid item xs={8}>
                <Typography variant="h6">{item.name}</Typography>
                <Typography variant="span">{item.description}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Stack direction="row">
                  <ButtonGroup orientation="vertical" variant="text">
                    <Button
                      aria-label="edit"
                      sx={{ color: palette.primary.main }}
                      onClick={() => {
                        setFormInput({ ...item, index: index })
                        handleOpen()
                      }}
                    >
                      <PencilSquareIcon />
                    </Button>
                    <Button
                      aria-label="delete"
                      sx={{ color: palette.error.main }}
                      onClick={() => {
                        setItems((s) => {
                          let newState = [...s]
                          newState.splice(index, 1)
                          return newState
                        })
                      }}
                    >
                      <TrashIcon />
                    </Button>
                  </ButtonGroup>
                </Stack>
              </Grid>
            </Grid>
          ))}
        </Stack>
      </Stack>

      <Dialog
        fullScreen={fullScreen}
        p={"2"}
        fullWidth
        maxWidth={"md"}
        open={isOpen}
        onClose={handleClose}
      >
        <FormControl fullWidth>
          <DialogContent className="flex flex-col !space-y-4">
            <TextField
              label="Предложить требование"
              name="name"
              value={formInput.name ? formInput.name : ""}
              fullWidth
              onChange={handleInputChange}
            />
            <TextField
              label="Описание"
              name="description"
              value={formInput.description ? formInput.description : ""}
              fullWidth
              multiline
              rows={4}
              onChange={handleInputChange}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="warning" onClick={handleClose}>
              Закрыть
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleAddRequirement()
                handleClose()
              }}
            >
              Подтвердить
            </Button>
          </DialogActions>
        </FormControl>
      </Dialog>
    </>
  )
}
