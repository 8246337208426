import { useState } from "react"
import Collapse from "@mui/material/Collapse"
import { Button, ButtonGroup } from "@mui/material"
import palette from "../../theme/palette"
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline"

export const Item = ({ object, setMainState, handleOpen, handleOpenDelete, objType }) => {
  const inheritState = () => {
    let obj = { ...object }
    obj.objType = objType
    setMainState(obj)
  }
  const [expanded, setExpanded] = useState(false)
  const handleExpandEnter = () => setExpanded(true)
  const handleExpandLeave = () => setExpanded(false)

  return (
    <li className=" ">
      <div>
        <div
          onMouseEnter={handleExpandEnter}
          onMouseLeave={handleExpandLeave}
          className="flex items-center hover:bg-slate-50 sm:px-2"
        >
          <div className="flex min-w-0 flex-1 items-center">
            <div className="min-w-0 flex-1 md:grid md:grid-cols-12  md:gap-4">
              <p className="col-span-9 my-2 border-0 border-r-2 border-solid border-slate-300">
                <span className="truncate font-medium">{object.name}</span>
                <span className="flex items-center pr-4 text-sm text-slate-500">
                  {object.description}
                </span>
              </p>
              <div className="col-span-3 col-end-auto hidden md:block">
                <div className={"flex flex-row"}>
                  <div className={"grow"}>
                    <p className="mt-2 mb-0 flex items-center ">
                      <span className=" self-center text-xl font-extrabold text-slate-900">
                        {object.time ? object.time : object.price ? object.price : ""}{" "}
                      </span>
                    </p>
                    <p className="mt-0 truncate text-sm text-slate-500">
                      {object.time
                        ? object.time === 1
                          ? "час"
                          : object.time === 2 || object.time === 3 || object.time === 4
                          ? "часа"
                          : "часов"
                        : object.price
                        ? "т. руб"
                        : ""}{" "}
                    </p>
                  </div>
                  <Collapse
                    className={"justify-items-end"}
                    in={expanded}
                    orientation={"horizontal"}
                    timeout="auto"
                    unmountOnExit
                  >
                    <div className={""}>
                      <ButtonGroup orientation="vertical" variant="text">
                        <Button
                          aria-label="edit"
                          sx={{ color: palette.primary.main }}
                          onClick={() => {
                            inheritState()
                            handleOpen()
                          }}
                        >
                          <PencilSquareIcon />
                        </Button>
                        <Button
                          aria-label="delete"
                          sx={{ color: palette.error.main }}
                          onClick={() => {
                            inheritState()
                            handleOpenDelete()
                          }}
                        >
                          <TrashIcon />
                        </Button>
                      </ButtonGroup>
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}
