export default function Divider(props) {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-0 border-t-4 border-solid border-slate-300" />
      </div>
      <div className="relative flex justify-center">
        <span className="h5 bg-white px-3 font-medium text-slate-600">{props.text}</span>
      </div>
    </div>
  )
}
