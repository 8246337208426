import { useDispatch } from "react-redux"

import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { ReqSelect } from "../Forms/RegReqForm"
import { useState } from "react"
import { timeoutUpdate } from "../../store/actions"

export const MainForm = ({ object, setMainState, handleOpen, objType }) => {
  let dispatch = useDispatch()
  const [timer, setTimer] = useState(0)

  const inputChange = (e) => {
    let obj = { ...object }
    obj[e.target.name] = e.target.value
    timeoutUpdate(dispatch, "reqs", obj, timer, setTimer, 1000)
  }
  const inheritState = () => {
    let obj = { ...object }
    obj.objType = objType
    setMainState(obj)
  }

  return (
    <>
      {/*<Divider className={"mb-4"} orientation={"horizontal"} variant={"fullWidth"}>*/}
      <div className={"mx-4 flex flex-col pb-6"}>
        <div className={"h5 self-center pb-4 text-center"}>{object.name}</div>
        <div className={"self-center text-left"}>{object.description}</div>
      </div>
      {/*</Divider>*/}
      <div className="flex flex-col space-y-4">
        <ReqSelect object={object} changeHandler={inputChange}></ReqSelect>

        <div className="flex flex-row space-x-2">
          <TextField
            name="scale_name"
            size="small"
            label="Маштаб (наименование)"
            fullWidth
            variant="outlined"
            value={object.scale_name ? object.scale_name : ""}
            onChange={inputChange}
          />
          <TextField
            type="number"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            name="scale_value"
            size="small"
            label="Значение ед."
            sx={{ width: "20ch" }}
            variant="outlined"
            value={object.scale_value ? object.scale_value : ""}
            onChange={inputChange}
          />
        </div>

        <div className="flex flex-row space-x-2">
          <TextField
            name="frequency"
            size="small"
            type="number"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            label="Частота, в ед."
            fullWidth
            variant="outlined"
            value={object.frequency ? object.frequency : ""}
            onChange={inputChange}
          />
          <FormControl sx={{ width: "20ch" }}>
            <InputLabel>Периодичность</InputLabel>
            <Select
              name="frequency_type"
              size="small"
              label="Периодичность"
              variant="outlined"
              value={object.frequency_type ? object.frequency_type : "мес"}
              onChange={inputChange}
            >
              <MenuItem value="нед">нед</MenuItem>
              <MenuItem value="мес">мес</MenuItem>
              <MenuItem value="год">год</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </>
  )
}
