import "./index.css"
import * as React from "react"
import { Provider } from "react-redux"
import { store } from "./store/store"
import { BrowserRouter as Router } from "react-router-dom"
import Template from "./components/SideBar/Template"
import ThemeProvider from "./theme"
import { AuthCheck } from "./components/AuthCheck"
import NotificationContainer from "react-notifications/lib/NotificationContainer"
import "react-notifications/lib/notifications.css"

function App() {
  return (
    <Router>
      <ThemeProvider>
        <Provider store={store}>
          <AuthCheck />
          <Template />
          <NotificationContainer />
        </Provider>
      </ThemeProvider>
    </Router>
  )
}

export default App
