import { createSlice } from "@reduxjs/toolkit"

export const regulationsOperator = createSlice({
  name: "regulations",
  initialState: {
    regs: [],
    reqs: [],
    actions: [],
    buyList: [],
    poll: {},
    questions: [],
    templates: {},
  },
  reducers: {
    formSubmit: (state, action) => {
      // objType: regs || reqs, actionType: create || update || delete, object
      let objType = action.payload.objType,
        actionType = action.payload.actionType,
        object = action.payload.object
      if (
        actionType === "create" &&
        (objType !== "reqs" || state.regs.find((e) => e.main).selected)
      ) {
        if (objType === "regs" && state.regs.length === 0) {
          object.main = true
        } else if (objType === "questions") {
          state[objType] = object // override existing questions to keep the order
          return
        }
        state[objType].push(object) // default behaviour
      }
      if (actionType === "update") {
        state[objType].some((e, i) => {
          if (e.id === object.id) {
            state[objType][i] = object
            return true
          }
          return false
        })
      }
      if (actionType === "delete") {
        state[objType] = state[objType].filter((e) => e.id !== object.id)
      }
      // update regulation price
      if (object.regulation_price !== undefined) {
        state.regs.some((el, ind) => {
          if (el.id === object.regulation) {
            state.regs[ind].price = parseFloat(object.regulation_price).toFixed(2)
            state.regs[ind].status = "Не утвержден"
            return true
          } else {
            return false
          }
        })
      }
      // update requirement price
      if (object.requirement_price !== undefined) {
        state.reqs.some((el, ind) =>
          el.selected
            ? (state.reqs[ind].calc_price = parseFloat(object.requirement_price).toFixed(2))
            : null
        )
      }
    },

    setSelectedState: (state, action) => {
      // objType: regs || reqs, object: object
      let objType = action.payload.objType,
        object = action.payload.object
      state[objType].forEach((e) => {
        if (e.id === object.id) {
          e.selected = !e.selected
        } else if (objType === "reqs") {
          e.selected = false
        }
      })
    },
    setObjectAsMain: (state, action) => {
      state.regs.forEach((e) => (e.main = e.id === action.payload.objectID))
    },
    writeRegulations: (state, action) => {
      state.regs = action.payload.data
    },
    writeRequirements: (state, action) => {
      action.payload.data.forEach((e) => {
        if (!state.reqs.find((rm) => rm.id === e.id)) {
          state.reqs.push(e)
        }
      })
    },
    deleteRequirements: (state, action) => {
      state.reqs = state.reqs.filter((e) => !action.payload.arr.find((rm) => rm.id === e.id))
    },
    deleteRegulationFile: (state, action) => {
      state.regs[action.payload.regIndex].files.splice(action.payload.fileIndex, 1)
    },
    writeTemplates: (state, action) => {
      state.templates[action.payload.objType] = action.payload.data
    },
    fetchData: (state, action) => {
      let data = action.payload.data
      Object.keys(data).forEach((key) => (state[key] = data[key]))
    },
  },
})

export const {
  formSubmit,
  setSelectedState,
  setObjectAsMain,
  writeRequirements,
  deleteRequirements,
  deleteRegulationFile,
  writeTemplates,
  fetchData,
} = regulationsOperator.actions
