import { MainForm } from "./MainForm"
import { ItemList } from "./ItemList"
import { TextField } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { actionsArr, buyListArr } from "../../store/selectors"
import { useState } from "react"
import { timeoutUpdate } from "../../store/actions"

export const RequirementDetails = ({
  setMainState,
  handleOpen,
  handleOpenDelete,
  selectedRequirement,
}) => {
  let dispatch = useDispatch()
  let buyListObjects = useSelector(buyListArr)
  let actionsObjects = useSelector(actionsArr)
  const [timer, setTimer] = useState(0)

  const [templatesOpen, setTemplatesModalOpen] = useState(false)
  const handleTemplatesOpen = () => setTemplatesModalOpen(true)
  const handleTemplatesClose = () => setTemplatesModalOpen(false)

  const handleSalaryChange = (e) => {
    let object = { ...selectedRequirement },
      val = e.target.value
    val = val.replace(",", ".")
    if (val !== "" && val.slice(-1) !== "." && val.slice(-1) !== "0") {
      val = parseFloat(val) || 0
    }
    object.salary = val
    timeoutUpdate(dispatch, "reqs", object, timer, setTimer, 1000)
  }

  return (
    <div className="mr-4 flex flex-1 flex-col space-y-2 space-x-2">
      <MainForm object={selectedRequirement} />
      <ItemList
        objects={actionsObjects}
        requirementObj={selectedRequirement}
        setMainState={setMainState}
        handleOpen={handleOpen}
        handleOpenDelete={handleOpenDelete}
        handleTemplatesOpen={handleTemplatesOpen}
        objType="actions"
      />
      <TextField
        sx={{ width: "30ch" }}
        type="text"
        label="Средняя заработная плата"
        name="salary"
        size="small"
        value={selectedRequirement.salary ? selectedRequirement.salary : 0}
        onChange={handleSalaryChange}
      />
      <ItemList
        objects={buyListObjects}
        requirementObj={selectedRequirement}
        setMainState={setMainState}
        handleOpen={handleOpen}
        handleOpenDelete={handleOpenDelete}
        objType="buyList"
      />
    </div>
  )
}
