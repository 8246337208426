import axios from "axios"
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { DeleteObjectModal } from "./DeleteObjectModal"
import { createObject, updateObject } from "../../store/actions"
import React, { useState } from "react"
import {
  ALL_ACTIONS_API_URL,
  ALL_BUYLIST_API_URL,
  ALL_REGULATIONS_API_URL,
  ALL_REQUIREMENTS_API_URL,
  SEARCH_TEMPLATES_API_URL,
} from "../../api/regulations"
import { templatesArr } from "../../store/selectors"
import { writeTemplates } from "../../store/slices/regulationsSlice"
import Cookies from "js-cookie"

export const ReqSelect = ({ object, changeHandler }) => (
  <FormControl fullWidth>
    <InputLabel>Тип информационного элемента</InputLabel>
    <Select
      name="type"
      size="small"
      label="Тип информационного элемента"
      variant="outlined"
      value={object.type ? object.type : ""}
      onChange={changeHandler}
    >
      <MenuItem value="">N/A</MenuItem>
      <MenuItem value="Издержки">Издержки</MenuItem>
      <MenuItem value="Выгоды">Выгоды</MenuItem>
    </Select>
  </FormControl>
)

export const RegReqForm = ({
  object,
  objType,
  setMainState,
  isOpen,
  isOpenDelete,
  handleClose,
  handleCloseDelete,
}) => {
  let dispatch = useDispatch()

  let objTypeVars = {
    regs: { name: "нормативный акт", searchIndex: "regulations" },
    reqs: { name: "требование", searchIndex: "requirements" },
    actions: { name: "информационное обязательство", searchIndex: "actions" },
    buyList: { name: "требование данных", searchIndex: "buylist" },
  }
  let objTypeName = objType ? objTypeVars[objType].name : ""

  let templates = useSelector(templatesArr)
  const [selectedTemplate, setSelectedTemplate] = useState({})
  const [timer, setTimer] = useState(0)

  const [templatesAreOpen, setTemplatesOpen] = useState(false)
  const handleTemplatesOpen = () => setTemplatesOpen(true)
  const handleTemplatesClose = () => setTemplatesOpen(false)
  const handleAutoCompleteChange = (e, inputVal, type) => {
    if (type === "reset") {
      return
    }

    clearTimeout(timer)

    const newTimer = setTimeout(() => {
      axios
        .post(SEARCH_TEMPLATES_API_URL, {
          index: objTypeVars[objType].searchIndex,
          query: inputVal,
        })
        .then(
          (r) => {
            return r.data.length > 0
              ? dispatch(writeTemplates({ objType: objType, data: r.data }))
              : null
          },
          (error) => {
            console.log(error)
          }
        )
    }, 500)

    setTimer(newTimer)
  }

  let specialField, hiddenField
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"))
  const inputChange = (e) => {
    let obj = { ...object }
    obj[e.target.name] = e.target.value
    setMainState(obj)
  }

  if (objType === "reqs") {
    hiddenField = (
      <TextField
        margin="normal"
        name="regulation"
        value={object.regulation}
        style={{ display: "none" }}
      />
    )
    specialField = <ReqSelect object={object} changeHandler={inputChange} />
  } else if (objType === "buyList" || objType === "actions") {
    hiddenField = (
      <TextField
        name="requirement"
        size="small"
        margin="normal"
        value={object.requirement}
        style={{ display: "none" }}
      />
    )
    specialField = (
      <TextField
        name={objType === "actions" ? "time" : "price"}
        label={objType === "actions" ? "Затраченное время, в часах" : "Стоимость в руб."}
        sx={{ mb: 2 }}
        size="small"
        fullWidth
        margin="normal"
        variant="outlined"
        value={object.time ? object.time : object.price ? object.price : 0}
        onChange={inputChange}
      />
    )
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        p={"2"}
        fullWidth
        maxWidth={"md"}
        open={isOpen}
        onClose={handleClose}
      >
        <DialogTitle className="h5">
          {object.id ? "Редактировать " : "Добавить "}
          {objTypeName}
        </DialogTitle>
        <DialogContent className="flex flex-col">
          <TextField
            name="id"
            value={object.id ? object.id : ""}
            style={{ display: "none" }}
          ></TextField>
          {hiddenField}
          <TextField
            name="name"
            size="small"
            label="Наименование"
            margin="normal"
            fullWidth
            variant="outlined"
            value={object.name ? object.name : ""}
            onChange={inputChange}
          />
          {specialField}
          <TextField
            name="description"
            size="small"
            label="Описание"
            margin="normal"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={object.description ? object.description : ""}
            onChange={inputChange}
          />
          {objType === "regs" ? (
            <>
              <Button variant="contained" component="label">
                Upload File
                <input
                  type="file"
                  name="files"
                  multiple
                  hidden
                  onChange={(e) =>
                    setMainState((s) => {
                      return { ...s, uploadFiles: e.target.files }
                    })
                  }
                />
              </Button>
              <Stack spacing={1} direction="row">
                {object.uploadFiles
                  ? [...object.uploadFiles].map((item, index) => (
                      <Typography key={index} color="green">
                        {item.name}
                      </Typography>
                    ))
                  : null}
              </Stack>
              <Stack spacing={1}>
                {object.files
                  ? [...object.files].map((item, index) => (
                      <Typography key={index} color="red">
                        {item.name}
                      </Typography>
                    ))
                  : null}
              </Stack>
            </>
          ) : null}
          <DialogActions spacing={2} direction="row">
            <Button variant="contained" color="warning" onClick={handleClose}>
              Закрыть
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (!object.id || object.template) {
                  createObject(dispatch, objType, object)
                } else {
                  updateObject(dispatch, objType, object)
                }
                handleClose()
              }}
            >
              Подтвердить
            </Button>
            {!object.id ? (
              <Button variant="outlined" color="primary" onClick={handleTemplatesOpen}>
                Шаблон
              </Button>
            ) : null}

            <Dialog
              fullScreen={fullScreen}
              fullWidth
              maxWidth={"md"}
              open={templatesAreOpen}
              onClose={handleTemplatesClose}
            >
              <DialogContent>
                <DialogTitle variant="h6" component="h2" mb={2}>
                  Выберите шаблон {/*{objTypeName}*/}
                </DialogTitle>
                <Autocomplete
                  // disablePortal
                  label="Выберите шаблон"
                  options={templates[objType] ? templates[objType] : []}
                  onInputChange={handleAutoCompleteChange}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => <TextField {...params} label="Шаблон" />}
                  onChange={(e, v) => setSelectedTemplate(v)}
                  fullWidth
                />
                <DialogActions>
                  <Button variant="contained" color="warning" onClick={handleTemplatesClose}>
                    Закрыть
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      let newObj = { ...selectedTemplate, objType: objType, template: true }
                      // eslint-disable-next-line no-unused-expressions
                      newObj.selected ? (newObj.selected = false) : null
                      if (objType === "reqs") {
                        newObj.regulation = object.regulation
                      } else if (objType === "actions" || objType === "buyList") {
                        newObj.id = object.id
                        newObj.requirement = object.requirement
                      }
                      setMainState(newObj)
                      createObject(dispatch, objType, newObj)
                      handleTemplatesClose()
                      handleClose()
                    }}
                  >
                    Подтвердить
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <DeleteObjectModal
        object={object}
        isOpenDelete={isOpenDelete}
        handleCloseDelete={handleCloseDelete}
      />
    </>
  )
}
