import axios from "axios"
import { formSubmit } from "./slices/regulationsSlice"
import {
  ACTIONS_CREATE_API_URL,
  ACTIONS_UPDATE_DESTROY_API_URL,
  BUYLIST_CREATE_API_URL,
  BUYLIST_UPDATE_DESTROY_API_URL,
  QUESTIONS_CREATE_API_URL,
  QUESTIONS_DESTROY_API_URL,
  REGULATIONS_CREATE_API_URL,
  REGULATIONS_UPDATE_DESTROY_API_URL,
  REQUIREMENTS_CREATE_API_URL,
  REQUIREMENTS_UPDATE_DESTROY_API_URL,
} from "../api/regulations"
import Cookies from "js-cookie"
import { NotificationManager } from "react-notifications"

const urls = (objectId) => {
  return {
    create: {
      regs: REGULATIONS_CREATE_API_URL,
      reqs: REQUIREMENTS_CREATE_API_URL,
      actions: ACTIONS_CREATE_API_URL,
      buyList: BUYLIST_CREATE_API_URL,
      questions: QUESTIONS_CREATE_API_URL,
    },
    delete: {
      regs: REGULATIONS_UPDATE_DESTROY_API_URL(objectId),
      reqs: REQUIREMENTS_UPDATE_DESTROY_API_URL(objectId),
      actions: ACTIONS_UPDATE_DESTROY_API_URL(objectId),
      buyList: BUYLIST_UPDATE_DESTROY_API_URL(objectId),
      questions: QUESTIONS_DESTROY_API_URL(objectId),
    },
    update: {
      regs: REGULATIONS_UPDATE_DESTROY_API_URL(objectId),
      reqs: REQUIREMENTS_UPDATE_DESTROY_API_URL(objectId),
      actions: ACTIONS_UPDATE_DESTROY_API_URL(objectId),
      buyList: BUYLIST_UPDATE_DESTROY_API_URL(objectId),
    },
  }
}

const convertToFormData = (object) => {
  let formData = new FormData()
  for (let k1 in object) {
    if (typeof object[k1] === "object" && object[k1] !== null) {
      // condition to catch FileList
      for (let k2 of object[k1]) {
        formData.append(k2.name, k2)
      }
    } else {
      formData.append(k1, object[k1])
    }
  }
  return formData
}

export const createObject = (dispatch, objType, object, extraData) => {
  let id = object.id,
    newObj = { ...object },
    type = "application/json"
  if (objType === "regs") {
    newObj = convertToFormData(newObj)
    type = "multipart/form-data"
  }
  axios
    .post(urls(id).create[objType], newObj, {
      headers: { Authorization: `Token ${Cookies.get("access_token")}`, "Content-Type": type },
    })
    .then((r) => {
      let obj = extraData ? { ...r.data, ...extraData } : r.data
      dispatch(formSubmit({ objType: objType, actionType: "create", object: obj }))
      NotificationManager.success(`${objType}`, "Объект создан")
    })
}

export const updateObject = (dispatch, objType, object, extraData) => {
  let id = object.id,
    newObj = { ...object },
    type = "application/json"
  if (objType === "regs") {
    newObj = convertToFormData(newObj)
    type = "multipart/form-data"
  }
  axios
    .put(urls(id).update[objType], newObj, {
      headers: { Authorization: `Token ${Cookies.get("access_token")}`, "Content-Type": type },
    })
    .then((r) => {
      let obj = extraData ? { ...r.data, ...extraData } : r.data
      dispatch(formSubmit({ objType: objType, actionType: "update", object: obj }))
      NotificationManager.success(`${objType}`, "Объект отредактирован")
    })
}

export const timeoutUpdate = (dispatch, objType, object, timer, setTimer, timeout) => {
  clearTimeout(timer)
  dispatch(formSubmit({ objType: objType, actionType: "update", object: object }))
  const newTimer = setTimeout(() => {
    // double store dispatch to update the object after the timeout time
    axios
      .put(urls(object.id).update[objType], object, {
        headers: { Authorization: `Token ${Cookies.get("access_token")}` },
      })
      .then((r) => dispatch(formSubmit({ objType: objType, actionType: "update", object: r.data })))
  }, timeout)
  // NotificationManager.info(`${objType}`, "Цена обновленна")
  setTimer(newTimer)
}

export const deleteObject = (dispatch, objType, object) => {
  axios
    .delete(urls(object.id).delete[objType], {
      headers: { Authorization: `Token ${Cookies.get("access_token")}` },
    })
    .then((r) => {
      dispatch(
        formSubmit({ objType: objType, actionType: "delete", object: { ...object, ...r.data } })
      )
      NotificationManager.warning(`${objType}`, "deleteObject")
    })
}
