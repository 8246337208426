import { BanknotesIcon } from "@heroicons/react/24/outline"

const stats = [
  {
    id: 1,
    change: "122",
    changeType: "increase",
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function MainStats({ selectedReg }) {
  return (
    (selectedReg.price || selectedReg.price === 0) && (
      <div className="flex">
        <div className="mr-4 flex-shrink-0 self-center">
          <BanknotesIcon
            className="ml-4 inline-block h-12 w-12 rounded-md bg-slate-500 p-1 text-slate-100"
            aria-hidden="true"
          />
        </div>
        {stats.map((item) => (
          <div key="1" className="pt-6">
            <span className="text-sm uppercase text-slate-400">Общая стоимость</span>

            <h4
              className={classNames(
                item.changeType === "increase" ? "text-teal-500" : "text-red-600",
                "mt-0 text-xl font-bold"
              )}
            >
              {selectedReg.id ? selectedReg.price : null}
            </h4>
          </div>
        ))}
      </div>
    )
  )
}
