import axios from "axios"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { SAVED_DATA_API_URL } from "../api/regulations"
import { fetchData } from "../store/slices/regulationsSlice"
import { RequirementDetails } from "../components/RequirementDetails/RequirementDetails"
import { RegReqForm } from "../components/Forms/RegReqForm"
import NestedList from "../components/Regulations/RegReqFullList"
import Cookies from "js-cookie"
import { selectedRequirement } from "../store/selectors"
import { RegulationDetails } from "../components/RegulationDetails/RegulationDetails"

const HomePageWrapper = () => {
  let dispatch = useDispatch()
  useEffect(() => {
    let selReg,
      selReq,
      data = {}
    axios
      .post(
        SAVED_DATA_API_URL,
        {},
        { headers: { Authorization: `Token ${Cookies.get("access_token")}` } }
      )
      .then(
        (r) => {
          selReg = r.data.selected_regulation || {}
          selReq = r.data.selected_requirement || {}
          data.regs = r.data.regulations
          if (data.regs.length === 1) {
            data.regs[0].main = true
          }
          data.reqs = r.data.requirements
          data.buyList = r.data.buyList
          data.actions = r.data.actions

          for (let [k1, v1] of Object.entries({ regs: data.regs, reqs: data.reqs })) {
            let compareId = k1 === "regs" ? selReg.id : k1 === "reqs" ? selReq.id : undefined
            let result =
              k1 === "regs"
                ? { selected: true, main: true }
                : k1 === "reqs"
                ? { selected: true }
                : {}
            if (Array.isArray(v1)) {
              v1.every((e, index) => {
                if (e.id === compareId) {
                  v1[index] = { ...e, ...result }
                  return false
                }
                return true
              })
            }
          }

          dispatch(fetchData({ data: data }))
        },
        (error) => {
          if (error.response.status === 401) {
            console.log("No credentials received! Redirecting to login page")
            Cookies.remove("access_token")
          }
        }
      )
  }, [dispatch])
  let selectedReq = useSelector(selectedRequirement)

  const [mainState, setMainState] = useState({})
  const [isOpen, setOpen] = useState(false)
  const [isOpenDelete, setOpenDelete] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleOpenDelete = () => setOpenDelete(true)
  const handleCloseDelete = () => setOpenDelete(false)

  return (
    // {setMainState, handleOpen, handleOpenDelete}
    <>
      <section
        aria-labelledby="primary-heading"
        className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last"
      >
        {selectedReq.id ? (
          <RequirementDetails
            setMainState={setMainState}
            handleOpen={handleOpen}
            handleOpenDelete={handleOpenDelete}
            selectedRequirement={selectedReq}
          />
        ) : (
          <RegulationDetails setMainState={setMainState} handleOpenDelete={handleOpenDelete} />
        )}
        <RegReqForm
          object={mainState}
          objType={mainState.objType}
          setMainState={setMainState}
          isOpen={isOpen}
          isOpenDelete={isOpenDelete}
          handleClose={handleClose}
          handleCloseDelete={handleCloseDelete}
        />
      </section>
      <aside className="order-1 hidden md:block md:flex-shrink-0">
        <div className="relative flex h-full w-96 flex-col overflow-y-auto border-0 border-r-2 border-solid border-slate-300 p-2">
          <NestedList
            setMainState={setMainState}
            handleOpen={handleOpen}
            handleOpenDelete={handleOpenDelete}
          />
          {/*<RegReqList*/}
          {/*/>*/}
        </div>
      </aside>
    </>
  )
}

export const Home = () => {
  return <HomePageWrapper />
}
