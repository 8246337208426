import axios from "axios"
import { Button, Container, Stack, TextField } from "@mui/material"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { FETCH_AUTH_TOKEN } from "../api/regulations"
import Cookies from "js-cookie"

export const Login = () => {
  let navigate = useNavigate()

  const [formInput, setFormInput] = useState({})

  const fetchToken = () => {
    axios.post(FETCH_AUTH_TOKEN, formInput).then((r) => {
      if (r.data.token) {
        Cookies.set("access_token", r.data.token)
        navigate("/")
      }
    })
  }

  const handleChange = (e) =>
    setFormInput((s) => {
      let newObj = { ...s }
      newObj[e.target.name] = e.target.value
      return newObj
    })

  return (
    <Container>
      <Stack spacing={2} p={2}>
        <TextField name="username" label="Имя пользователя" onChange={handleChange} />
        <TextField name="password" label="Пароль" type="password" onChange={handleChange} />
      </Stack>
      <Button onClick={fetchToken} variant="outlined">
        Отправить
      </Button>
    </Container>
  )
}

export const logOut = (navigate) => {
  Cookies.remove("access_token")
  navigate("/login")
}
