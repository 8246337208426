import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Rating,
  Select,
  Slider,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useDispatch } from "react-redux"
import React, { useState } from "react"
import { formSubmit } from "../../store/slices/regulationsSlice"
import { deleteObject } from "../../store/actions"
import { BaseSettings } from "./BaseSettings"
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline"
import { AddRequirement } from "./AddRequirement"

export const CompiledInput = ({ settings, onChange, redactor, handleChangeArrayInput }) => {
  let dispatch = useDispatch()
  let inputValChange = redactor ? () => {} : onChange
  const [value, setValue] = useState(0)
  const [options, setOptions] = useState(settings.field_settings.choices || [])
  const [isOpen, setOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)

  const handleSliderChange = (e) => setValue(Number(e.target.value))
  const handleInputChange = (e) => setValue(Number(e.target.value))
  const handleOptionValueChange = (e, index) => {
    setOptions((s) => {
      let newArr = [...s]
      newArr[index] = e.target.value
      return newArr
    })
  }
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleEditModalOpen = () => setEditModalOpen(true)
  const handleEditModalClose = () => setEditModalOpen(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"))
  const addOption = () => setOptions((s) => ["Новый выбор", ...s])
  const removeOption = (index) =>
    setOptions((s) => {
      let newArr = [...s]
      newArr.splice(index, 1)
      return newArr
    })

  let config = {
    text: {
      field: (
        <FormControl fullWidth>
          <FormLabel className="h5 pb-4">{settings.name}</FormLabel>
          <TextField
            fullWidth
            id={settings.id.toString()}
            name={settings.name}
            multiline
            label={settings.name}
            onChange={(e) => inputValChange(e, settings.id)}
          />
          <span className="text-muted pt-2 text-justify text-sm">{settings.help_text}</span>
        </FormControl>
      ),
      redactor: <BaseSettings settings={settings} onChange={onChange} />,
    },
    description: {
      field: (
        <FormControl fullWidth>
          <div className="h5 pb-4">{settings.name}</div>
          <span className="text-muted pt-2 text-justify text-sm">{settings.help_text}</span>
        </FormControl>
      ),
      redactor: <BaseSettings settings={settings} onChange={onChange} />,
    },
    select: {
      field: (
        <FormControl fullWidth>
          <div className="h5 pb-4">{settings.name}</div>
          <Select
            fullWidth
            id={settings.id.toString()}
            name={settings.name}
            label={settings.name}
            onChange={(e) => inputValChange(e, settings.id)}
            defaultValue="0"
          >
            <MenuItem value="0">Выберите значение</MenuItem>
            {options.map((item, index) => (
              <MenuItem id={settings.id.toString()} key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          <span className="text-muted pt-2 text-justify text-sm">{settings.help_text}</span>
        </FormControl>
      ),
      redactor: (
        <>
          <BaseSettings settings={settings} onChange={onChange} />
          <Button variant="outlined" onClick={handleOpen}>
            Настроить поле выбора
          </Button>
          <Dialog
            fullScreen={fullScreen}
            p={"2"}
            fullWidth
            maxWidth={"md"}
            open={isOpen}
            onClose={handleClose}
          >
            <DialogContent>
              {options.map((item, index) => (
                <Box key={index} minWidth={200}>
                  <TextField
                    name="optionValue"
                    value={item}
                    onChange={(e) => handleOptionValueChange(e, index)}
                  />
                  <Button size="small" onClick={() => removeOption(index)}>
                    <TrashIcon className="h5 w-5 text-red-600" />
                  </Button>
                </Box>
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={addOption}>Добавить выбор</Button>
              <Button
                onClick={() => {
                  let obj = { ...settings }
                  obj.field_settings = { ...obj.field_settings }
                  obj.field_settings.choices = options
                  dispatch(formSubmit({ objType: "questions", actionType: "update", object: obj }))
                  handleClose()
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ),
    },
    slider: {
      field: (
        <FormControl fullWidth>
          <FormLabel className="h5 pb-4">{settings.name}</FormLabel>
          <div className="flex flex-row">
            <Slider
              className="w-full"
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              id={settings.id.toString()}
              name={settings.name}
              label={settings.name}
              value={typeof value === "number" ? value : 0}
              onChange={(e) => {
                handleSliderChange(e)
                inputValChange(e, settings.id)
              }}
              step={10}
              marks
              min={0}
              max={100}
              valueLabelDisplay="auto"
            />
            {/*<Input*/}
            {/*  name={settings.name}*/}
            {/*  size="small"*/}
            {/*  value={value ? value : 0}*/}
            {/*  onChange={(e) => {*/}
            {/*    handleInputChange(e)*/}
            {/*    onChange(e, settings.id)*/}
            {/*  }}*/}
            {/*  inputProps={{ type: "number" }}*/}
            {/*/>*/}
          </div>
          <span className="text-muted pt-2 text-justify text-sm">{settings.help_text}</span>
        </FormControl>
      ),
      redactor: <BaseSettings settings={settings} onChange={onChange} />,
    },
    addRequirement: {
      field: <AddRequirement settings={settings} handleChangeArrayInput={handleChangeArrayInput} />,
      redactor: <BaseSettings settings={settings} onChange={onChange} />,
    },
    rating: {
      field: (
        <FormControl fullWidth>
          <div className="h5 pb-4">{settings.name}</div>
          <Rating
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            id={settings.id}
            name={settings.name}
            max={10}
            onChange={(e) => inputValChange(e, settings.id)}
          />
          <span className="text-muted pt-2 text-justify text-sm">{settings.help_text}</span>
        </FormControl>
      ),
      redactor: <BaseSettings settings={settings} onChange={onChange} />,
    },
  }
  return redactor ? (
    <div className="flex items-center justify-between">
      {config[settings.type].field}

      <div className="flex flex-col">
        {config[settings.type].redactor ? (
          <IconButton
            variant="text"
            aria-label="edit"
            color={"primary"}
            onClick={handleEditModalOpen}
          >
            <PencilSquareIcon className={"h-5 w-5"} />
          </IconButton>
        ) : null}
        <IconButton
          variant="text"
          aria-label="delete"
          color="error"
          onClick={() => {
            deleteObject(dispatch, "questions", { id: settings.id })
          }}
        >
          <TrashIcon className="h-5 w-5" />
        </IconButton>
      </div>

      <Dialog
        fullScreen={fullScreen}
        p={"2"}
        fullWidth
        maxWidth={"md"}
        open={editModalOpen}
        onClose={handleEditModalClose}
      >
        <DialogContent>{config[settings.type].redactor}</DialogContent>
        <DialogActions>
          <Button variant="contained" color="warning" onClick={handleEditModalClose}>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : (
    <>{config[settings.type].field}</>
  )
}
