import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Cookies from "js-cookie"

// NOTE: temp solution: should make it into function only instead of component
export const AuthCheck = () => {
  let navigate = useNavigate()
  useEffect(() => {
    let access_token = Cookies.get("access_token") || ""
    // eslint-disable-next-line no-restricted-globals
    if (access_token.length < 10 && location.pathname !== "/login") {
      return navigate("/login")
    }
  })
}
