import { Card } from "@mui/material"
import { ChatBubbleLeftEllipsisIcon, StarIcon } from "@heroicons/react/20/solid"
import { HandRaisedIcon } from "@heroicons/react/24/outline"
import { QueueListIcon } from "@heroicons/react/24/solid"

export const InputTypeCard = ({ provided, type, text }) => {
  return (
    <Card
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      size="large"
      label={text}
      variant="outlined"
      className={"mb-2 selection:shadow-2xl  hover:p-0.5 hover:shadow-2xl"}
    >
      <div className="m-2 flex items-center justify-between">
        <div>
          <div className="h5 mb-2 text-sm text-slate-500">{text}</div>
          {type === "rating" ? (
            <div className="mb-2 flex animate-pulse items-center space-x-1">
              <StarIcon className="h-3 w-3 text-yellow-200" />
              <StarIcon className="h-3 w-3 text-yellow-200" />
              <StarIcon className="h-3 w-3 text-yellow-200" />
              <StarIcon className="h-3 w-3 text-yellow-200" />
              <StarIcon className="h-3 w-3 text-yellow-200" />
              <StarIcon className="h-3 w-3 text-yellow-200" />
              <StarIcon className="h-3 w-3 text-yellow-200" />
              <StarIcon className="h-3 w-3 text-yellow-200" />
              <StarIcon className="h-3 w-3 text-slate-200" />
              <StarIcon className="h-3 w-3 text-slate-200" />
            </div>
          ) : type === "slider" ? (
            <div className="mb-1.5 flex animate-pulse items-center space-x-1">
              <div className="h-2.5 w-14 animate-pulse rounded-full bg-gray-200 dark:bg-gray-600"></div>
              <div className="h-3 w-2 animate-pulse rounded-full bg-gray-200 dark:bg-gray-600"></div>
              <div className="h-2.5 w-24 animate-pulse rounded-full bg-gray-200 dark:bg-gray-600"></div>
            </div>
          ) : type === "addRequirement" ? (
            <div className="mb-2 flex animate-pulse items-center space-x-1">
              <div className="h-2.5 w-40 animate-pulse rounded-full bg-gray-200 dark:bg-gray-600"></div>
              <ChatBubbleLeftEllipsisIcon className="h-4 w-4 text-slate-200" />
            </div>
          ) : type === "select" ? (
            <div className="mb-2 flex animate-pulse items-center -space-x-2 text-slate-300">
              <QueueListIcon className="h-4 w-4 " />
              <QueueListIcon className="h-4 w-4" />
              <QueueListIcon className="h-4 w-4" />
              <QueueListIcon className="h-4 w-4" />
              <QueueListIcon className="h-4 w-4" />
              <QueueListIcon className="h-4 w-4" />
              <QueueListIcon className="h-4 w-4" />
              <QueueListIcon className="h-4 w-4" />
              <QueueListIcon className="h-4 w-4" />
              <QueueListIcon className="h-4 w-4" />
              <QueueListIcon className="h-4 w-4" />
              <QueueListIcon className="h-4 w-4" />
              <QueueListIcon className="h-4 w-4" />
            </div>
          ) : (
            <div className="mb-2 h-2.5 w-24 animate-pulse rounded-full bg-gray-200 dark:bg-gray-600"></div>
          )}
          <div className="h-2.5 w-32 animate-pulse rounded-full bg-gray-200 dark:bg-gray-700"></div>
        </div>
        <div className="relative flex h-5 w-5">
          <HandRaisedIcon className="absolute inline-flex h-full w-full animate-ping rounded-full text-slate-300 opacity-50" />
          <HandRaisedIcon className="relative inline-flex h-5 w-5 rounded-full text-slate-300" />
        </div>
      </div>
    </Card>
  )
}
