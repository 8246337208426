import List from "@mui/material/List"
import { Button, ButtonGroup } from "@mui/material"
import { regulationsArr, requirementsArr, selectedRegulation } from "../../store/selectors"
import { useSelector } from "react-redux"
import { RegReqItem } from "./RegReqFullListItem"

export default function NestedList({ setMainState, handleOpen, handleOpenDelete }) {
  const regulations = useSelector(regulationsArr)
  const requirements = useSelector(requirementsArr)
  const selectedReg = useSelector(selectedRegulation)

  return (
    <section
      aria-labelledby="side-norma-list"
      className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last"
    >
      <ButtonGroup variant="outlined">
        <Button
          name="RegulationForm"
          onClick={() => {
            setMainState({ objType: "regs" })
            handleOpen()
          }}
        >
          Добавить акт
        </Button>
        {selectedReg.id ? (
          <Button
            name="RequirementForm"
            onClick={() => {
              setMainState({ regulation: selectedReg.id, objType: "reqs" })
              handleOpen()
            }}
          >
            Добавить требование
          </Button>
        ) : null}
      </ButtonGroup>
      <List
        sx={{ width: "100%", maxWidth: "full", bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {regulations.map((item, index) => (
          <RegReqItem
            key={index}
            object={item}
            setMainState={setMainState}
            handleOpen={handleOpen}
            handleOpenDelete={handleOpenDelete}
            requirements={requirements}
            objType={"regs"}
          />
        ))}
      </List>
    </section>
  )
}
