import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import Cookies from "js-cookie"
import { regulationDetailsData } from "../../store/selectors"
import { Box, Button, ButtonGroup } from "@mui/material"
import palette from "../../theme/palette"
import { updateObject } from "../../store/actions"
import {
  POLL_STATS_API_URL,
  REGULATION_APPROVE_API_URL,
  REGULATION_FILE_DESTROY_API_URL,
} from "../../api/regulations"
import { deleteRegulationFile, formSubmit } from "../../store/slices/regulationsSlice"
import React, { useEffect, useState } from "react"
import Divider from "../Divider"
import { NotificationManager } from "react-notifications"
import {
  CheckBadgeIcon,
  CheckCircleIcon,
  DocumentTextIcon,
  TrashIcon,
} from "@heroicons/react/20/solid"

export const RegulationDetails = ({ setMainState, handleOpenDelete }) => {
  let dispatch = useDispatch()
  let regulationData = useSelector(regulationDetailsData)
  let selectedRegulation = regulationData.selectedRegulation
  let suggestedRequirements = regulationData.suggestedRequirements
  let [pollStats, setPollStats] = useState(undefined)

  useEffect(() => {
    if (selectedRegulation.id) {
      axios
        .get(`${POLL_STATS_API_URL}${selectedRegulation.id}/poll-stats`, {
          headers: { Authorization: `Token ${Cookies.get("access_token")}` },
        })
        .then(
          (r) => {
            setPollStats(r.data)
          },
          (error) => {
            console.log("No poll found for the chosen regulation")
          }
        )
    }
  }, [selectedRegulation.id])

  return selectedRegulation.id ? (
    <section
      aria-labelledby="primary-heading"
      className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto px-4 lg:order-last"
    >
      <div className="flex items-center space-x-2">
        {selectedRegulation.status === "Утвержден" ? (
          <CheckBadgeIcon className="h-[24px] w-[24px] text-green-500" />
        ) : (
          selectedRegulation.status === "Не утвержден" && (
            <CheckBadgeIcon
              className="h-[24px] w-[24px] text-red-500"
              onClick={() => {
                dispatch(
                  formSubmit({
                    objType: "regs",
                    actionType: "update",
                    selectedRegulation: { ...selectedRegulation, status: "Утвержден" },
                  })
                )
                axios
                  .put(
                    REGULATION_APPROVE_API_URL,
                    { regId: selectedRegulation.id },
                    { headers: { Authorization: `Token ${Cookies.get("access_token")}` } }
                  )
                  .then((r) => NotificationManager.success("message", "Утвержден"))
              }}
            />
          )
        )}
        <div className="h4">{selectedRegulation.name}</div>
      </div>
      <p className="">{selectedRegulation.description}</p>
      <Divider text="Загруженные файлы" />
      <div className="grid grid-cols-7 gap-1">
        {selectedRegulation.files.map((item, index) => (
          <div className="flex flex-col items-center text-blue-700" key={index}>
            <DocumentTextIcon className="h-12 w-12 items-center" />
            <a className="w-10/12" href={item.file} target="blank">
              <p className="my-0 overflow-hidden truncate text-ellipsis text-left text-xs">
                {item.name}
              </p>
            </a>
            <Button
              aria-label="delete"
              sx={{ color: palette.error.main }}
              onClick={() => {
                axios
                  .delete(REGULATION_FILE_DESTROY_API_URL(item.id), {
                    headers: { Authorization: `Token ${Cookies.get("access_token")}` },
                  })
                  .then((r) => NotificationManager.success("message", "Title here"))
                dispatch(
                  deleteRegulationFile({ regIndex: regulationData.regIndex, fileIndex: index })
                )
              }}
            >
              <TrashIcon className="h-5 w-5" />
            </Button>
          </div>
        ))}
      </div>
      <Divider text="Предложенные требования" />
      <div className="">
        {suggestedRequirements.map((item, index) => (
          <div key={index} className="flex items-center py-2 sm:px-6">
            <div className="flex min-w-0 flex-1 items-center">
              <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-12 md:gap-4">
                <div className="col-span-11">
                  <p className="truncate font-bold text-slate-900">{item.name}</p>
                  <div className="flex items-center text-sm text-slate-500">{item.description}</div>
                </div>
              </div>
            </div>
            <div>
              <ButtonGroup orientation="vertical" variant="text">
                <Button
                  aria-label="edit"
                  sx={{ color: palette.success.main }}
                  onClick={() => {
                    updateObject(dispatch, "reqs", { ...item, status: "Утвержден" })
                  }}
                >
                  <CheckCircleIcon className="h-5 w-5" />
                </Button>
                <Button
                  aria-label="delete"
                  sx={{ color: palette.error.main }}
                  onClick={() => {
                    setMainState({ ...item, objType: "reqs" })
                    handleOpenDelete()
                  }}
                >
                  <TrashIcon className="h-5 w-5" />
                </Button>
              </ButtonGroup>
            </div>
          </div>
        ))}
      </div>
      {pollStats ? (
        <>
          <Divider text="Статистика опроса по нормативному акту" />
          <Box p={2}>
            <Box>
              Количество пользователей, прошедших опрос: {pollStats.poll_entries.users_count}
            </Box>
            <Box>Всего ответов: {pollStats.poll_entries.answers_count}</Box>
          </Box>
        </>
      ) : null}
    </section>
  ) : null
}
