export const regulationsArr = (state) =>
  [...state.data.regs].sort((a, b) => Number(b.main) - Number(a.main))

export const templatesArr = (state) => state.data.templates

export const fetchQuestions = (state) => state.data.questions

export const fetchPollData = (state) => state.data.poll

export const selectedRegulation = (state) => state.data.regs.find((e) => e.main) || {}

export const selectedRequirement = (state) => state.data.reqs.find((e) => e.selected) || {}

export const requirementsArr = (state) =>
  state.data.reqs.filter((e) => e.status === "Утвержден" || e.status === "Не утвержден")

export const regulationDetailsData = (state) => {
  let regulation = {},
    regIndex = -1
  state.data.regs.find((e, index) => {
    if (e.main) {
      regulation = e
      regIndex = index
    }
    return 0
  })
  return {
    regIndex: regIndex,
    selectedRegulation: regulation,
    suggestedRequirements: state.data.reqs.filter(
      (e) => e.status === "Предложено" && e.regulation === regulation.id
    ),
  }
}

export const buyListArr = (state) => state.data.buyList

export const actionsArr = (state) => state.data.actions
