import { Item } from "./Item"
import { Button, Divider } from "@mui/material"
import { InformationCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline"

export const ItemList = ({
  objects,
  requirementObj,
  setMainState,
  handleOpen,
  handleOpenDelete,
  handleTemplatesOpen,
  objType,
}) => {
  return (
    <div className="overflow-hidden">
      <Divider orientation={"horizontal"} variant={"fullWidth"}>
        <div className={"flex flex-row"}>
          <div className={"h5 self-center text-center"}>
            {objType === "actions" ? "Информационные обязательства" : "Требования данных"}
          </div>
          <Button
            variant="text"
            onClick={() => {
              setMainState({ requirement: requirementObj.id, objType: objType })
              handleOpen()
            }}
          >
            <PlusCircleIcon className={"mr-1 h-6 w-6"} />
            Добавить
          </Button>
        </div>
      </Divider>
      <ul className="divide-y divide-gray-200">
        <li className="">
          <div className="flex items-center sm:px-1">
            <div className="min-w-0 flex-1 divide-x md:grid md:grid-cols-12 md:gap-4">
              <div className="col-span-9">
                <p className="mt-2 mb-0 flex items-center ">
                  <span className="h6 truncate">Наименование</span>
                  <InformationCircleIcon
                    className="ml-1 h-5 w-5 flex-shrink-0 text-slate-400"
                    aria-hidden="true"
                  />
                </p>
                <p className="mt-0 truncate text-sm text-slate-500">Описание</p>
              </div>
              <div className="col-span-3 hidden md:block">
                <div>
                  <p className="mt-2 mb-0 flex items-center ">
                    <span className="h6 truncate">
                      {objType === "actions" ? "Затрачиваемое время," : "Cтоимость,"}
                    </span>
                    <InformationCircleIcon
                      className="ml-1 h-5 w-5 flex-shrink-0 text-slate-400"
                      aria-hidden="true"
                    />
                  </p>
                  <p className="mt-0 truncate text-sm text-slate-500">
                    {objType === "actions" ? "в часах" : "в т.руб."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </li>
        {objects.map((item, index) => (
          <Item
            key={index}
            object={item}
            setMainState={setMainState}
            handleOpen={handleOpen}
            handleOpenDelete={handleOpenDelete}
            objType={objType}
          />
        ))}
      </ul>
    </div>
  )
}
