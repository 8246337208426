import { TextField } from "@mui/material"
import React from "react"

export const BaseSettings = ({ settings, onChange }) => {
  return (
    <>
      <div className="flex flex-col !space-y-4 ">
        <TextField
          name="name"
          fullWidth
          label="Название"
          value={settings.name ? settings.name : ""}
          onChange={(e) => onChange(e, settings.id)}
        />
        <TextField
          name="help_text"
          fullWidth
          label="Вспомогательный текст"
          value={settings.help_text ? settings.help_text : ""}
          onChange={(e) => onChange(e, settings.id)}
        />
      </div>
    </>
  )
}
