const BACKEND_URL = "https://orv.backend.staging.iddqd.pro"

export const FETCH_AUTH_TOKEN = `${BACKEND_URL}/api/token-auth/`

export const ALL_REGULATIONS_API_URL = `${BACKEND_URL}/api/regulations/list` // used for templates
export const REGULATIONS_UPDATE_DESTROY_API_URL = (regId) =>
  `${BACKEND_URL}/api/regulations/` + regId
export const REGULATIONS_CREATE_API_URL = `${BACKEND_URL}/api/regulations/create`
export const REGULATION_FILE_DESTROY_API_URL = (regFileId) =>
  `${BACKEND_URL}/api/regulations/regulation-file/` + regFileId
export const REGULATION_APPROVE_API_URL = `${BACKEND_URL}/api/regulations/approve`

export const ALL_REQUIREMENTS_API_URL = `${BACKEND_URL}/api/regulations/requirements/list`
export const REQUIREMENTS_API_URL = (regId) =>
  `${BACKEND_URL}/api/regulations/` + regId + "/requirements"
export const REQUIREMENTS_UPDATE_DESTROY_API_URL = (reqId) =>
  `${BACKEND_URL}/api/regulations/requirements/` + reqId
export const REQUIREMENTS_CREATE_API_URL = `${BACKEND_URL}/api/regulations/requirements/create`

export const ACTIONS_BUYLIST_URL = (reqId) =>
  `${BACKEND_URL}/api/regulations/` + reqId + "/actions-buylist"
export const ALL_ACTIONS_API_URL = `${BACKEND_URL}/api/regulations/actions/list` // used for templates
export const ACTIONS_UPDATE_DESTROY_API_URL = (actionId) =>
  `${BACKEND_URL}/api/regulations/actions/` + actionId
export const ACTIONS_CREATE_API_URL = `${BACKEND_URL}/api/regulations/actions/create`
export const ALL_BUYLIST_API_URL = `${BACKEND_URL}/api/regulations/buylist/list` // used for templates
export const BUYLIST_UPDATE_DESTROY_API_URL = (buyListId) =>
  `${BACKEND_URL}/api/regulations/buylist/` + buyListId
export const BUYLIST_CREATE_API_URL = `${BACKEND_URL}/api/regulations/buylist/create`

export const SAVED_DATA_API_URL = `${BACKEND_URL}/api/regulations/saved-data`
export const SET_MAIN_REGULATION_URL = `${BACKEND_URL}/api/regulations/set-main-regulation`

export const POLL_RETRIEVE_API_URL = (regId) => `${BACKEND_URL}/api/regulations/` + regId + "/poll"
export const POLL_STATS_API_URL = `${BACKEND_URL}/api/regulations/`
export const QUESTIONS_UPDATE_API_URL = (pollId) =>
  `${BACKEND_URL}/api/regulations/poll/` + pollId + "/questions/update"
export const QUESTIONS_CREATE_API_URL = `${BACKEND_URL}/api/regulations/questions/create`
export const QUESTIONS_DESTROY_API_URL = (qId) =>
  `${BACKEND_URL}/api/regulations/questions/` + qId + "/delete"
export const SUBMIT_ANSWERS_API_URL = `${BACKEND_URL}/api/regulations/answers/submit`

export const SEARCH_TEMPLATES_API_URL = `${BACKEND_URL}/api/regulations/search`
