import * as React from "react"
import { Fragment, useState } from "react"
import { Dialog, Menu, Transition } from "@headlessui/react"
import logo from "../../static/img/logo192.png"
import MainStats from "./MainStats"
import { ChevronDownIcon } from "@heroicons/react/24/solid"
import {
  AcademicCapIcon,
  Bars3Icon,
  BellIcon,
  UsersIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline"
import { Route, Routes, useNavigate } from "react-router-dom"
import { Home } from "../../pages/Home"
import { Poll } from "../../pages/Poll"
import { PollRedactor } from "../../pages/PollRedactor"
import { useSelector } from "react-redux"
import { selectedRegulation } from "../../store/selectors"
import { Login, logOut } from "../../pages/Login"
import { FormSuccess } from "../Forms/FormSuccess"

const user = {
  name: "Ирина Иванова",
  email: "ivanova.irina@example.com",
  imageUrl: "https://sapeha.site/assets/images/content/avatar/user3.jpg",
}
const navigation = [
  {
    name: "Меню",
    href: "#",
    children: [
      { name: "Нормативный акт", href: "/" },
      { name: "Мнение экспертов", href: "/poll" },
    ],
  },
  { name: "Наcтройки", href: "#", children: [] },
]
const sidebarNavigation = [
  { name: "Нормативный акт", href: "/", icon: AcademicCapIcon, current: true },
  { name: "Мнение экспертов", href: "/poll", icon: UsersIcon, current: false },
]
const userNavigation = [
  { name: "Профиль", href: "#" },
  { name: "Выход", href: "#" },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Template() {
  let navigate = useNavigate()
  const selectedReg = useSelector(selectedRegulation)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  // BUG: NEED TO KEEP selectedReg state between routes

  return (
    <>
      {}
      <div className="flex h-full flex-col">
        {/* Top nav*/}
        <header className="relative flex h-16 flex-shrink-0 items-center bg-white">
          {/* Logo area */}
          <div className="absolute inset-y-0 left-0 md:static md:flex-shrink-0">
            <a
              href="/"
              className="flex h-16 w-16 items-center justify-center bg-slate-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-600 md:w-20"
            >
              <img className="h-8 w-auto" src={logo} alt="Workflow" />
            </a>
          </div>

          {/* Picker area */}
          <div className="mx-auto md:hidden">
            <div className="relative">
              <label htmlFor="inbox-select" className="sr-only">
                Choose inbox
              </label>
              <select
                id="inbox-select"
                className="rounded-md border-0 bg-none pl-3 pr-8 text-base font-medium text-slate-900 focus:ring-2 focus:ring-slate-600"
                defaultValue={sidebarNavigation.find((item) => item.current).name}
              >
                {sidebarNavigation.map((item) => (
                  <option key={item.name}>{item.name}</option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center justify-center pr-2">
                <ChevronDownIcon className="h-5 w-5 text-slate-500" aria-hidden="true" />
              </div>
            </div>
          </div>

          {/* Menu button area */}
          <div className="absolute inset-y-0 right-0 flex items-center pr-4 sm:pr-6 md:hidden">
            {/* Mobile menu button */}
            <button
              type="button"
              className="-mr-2 inline-flex items-center justify-center rounded-md p-2 text-slate-400 hover:bg-slate-100 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-600"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          {/* Desktop nav area */}
          <div className="hidden md:flex md:min-w-0 md:flex-1 md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
              <MainStats selectedReg={selectedReg} />
            </div>
            <div className="ml-10 flex flex-shrink-0 items-center space-x-10 pr-4">
              <nav aria-label="Global" className="flex space-x-10">
                <a href="/" className="text-sm font-medium text-slate-900">
                  Настройки
                </a>
              </nav>
              <div className="flex items-center space-x-8">
                <span className="inline-flex">
                  <a
                    href="/"
                    className="-mx-1 rounded-full bg-white p-1 text-slate-400 hover:text-slate-500"
                  >
                    <span className="sr-only">Уведомления</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </a>
                </span>

                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-slate-600 focus:ring-offset-2">
                    <span className="sr-only">Меню пользователя</span>
                    <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-30 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="/"
                              className={classNames(
                                active ? "bg-slate-100" : "",
                                "block px-4 py-2 text-sm text-slate-700"
                              )}
                            >
                              Профиль
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href={navigation[1].href}
                              onClick={() => {
                                logOut(navigate)
                              }}
                              className={classNames(
                                active ? "bg-slate-100" : "",
                                "block px-4 py-2 text-sm text-slate-700"
                              )}
                            >
                              Выход
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          {/* Mobile menu, show/hide this `div` based on menu open/closed state */}
          <Transition.Root show={mobileMenuOpen} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-40 md:hidden" onClose={setMobileMenuOpen}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="hidden sm:fixed sm:inset-0 sm:block sm:bg-slate-600 sm:bg-opacity-75" />
              </Transition.Child>

              <Transition.Child
                as={Fragment}
                enter="transition ease-out duration-150 sm:ease-in-out sm:duration-300"
                enterFrom="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
                enterTo="transform opacity-100 scale-100  sm:translate-x-0 sm:scale-100 sm:opacity-100"
                leave="transition ease-in duration-150 sm:ease-in-out sm:duration-300"
                leaveFrom="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
                leaveTo="transform opacity-0 scale-110  sm:translate-x-full sm:scale-100 sm:opacity-100"
              >
                <nav
                  className="fixed inset-0 z-40 h-full w-full bg-white sm:inset-y-0 sm:left-auto sm:right-0 sm:w-full sm:max-w-sm sm:shadow-lg"
                  aria-label="Global"
                >
                  <div className="flex h-16 items-center justify-between px-4 sm:px-6">
                    <a href="/">
                      <img className="block h-8 w-auto" src={logo} alt="Workflow" />
                    </a>
                    <button
                      type="button"
                      className="-mr-2 inline-flex items-center justify-center rounded-md p-2 text-slate-400 hover:bg-slate-100 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-600"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <span className="sr-only">Close main menu</span>
                      <XCircleIcon className="block h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <div className="max-w-8xl mx-auto py-3 px-2 sm:px-4">
                    {navigation.map((item) => (
                      <Fragment key={item.name}>
                        <a
                          href={item.href}
                          className="block rounded-md py-2 px-3 text-base font-medium text-slate-900 hover:bg-slate-100"
                          onClick={(e) => {
                            e.preventDefault()
                            navigate(item.href)
                          }}
                        >
                          {item.name}
                        </a>
                        {item.children.map((child) => (
                          <a
                            key={child.name}
                            href={child.href}
                            className="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-slate-500 hover:bg-slate-100"
                          >
                            {child.name}
                          </a>
                        ))}
                      </Fragment>
                    ))}
                  </div>
                  <div className="border-t border-slate-200 pt-4 pb-3">
                    <div className="max-w-8xl mx-auto flex items-center px-4 sm:px-6">
                      <div className="flex-shrink-0">
                        <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                      </div>
                      <div className="ml-3 min-w-0 flex-1">
                        <div className="truncate text-base font-medium text-slate-800">
                          {user.name}
                        </div>
                        <div className="truncate text-sm font-medium text-slate-500">
                          {user.email}
                        </div>
                      </div>
                      <a
                        href="/"
                        className="ml-auto flex-shrink-0 bg-white p-2 text-slate-400 hover:text-slate-500"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </a>
                    </div>
                    <div className="max-w-8xl mx-auto mt-3 space-y-1 px-2 sm:px-4">
                      {userNavigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="block rounded-md py-2 px-3 text-base font-medium text-slate-900 hover:bg-slate-50"
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </nav>
              </Transition.Child>
            </Dialog>
          </Transition.Root>
        </header>

        {/* Bottom section */}
        <div className="flex flex-1">
          {/* Narrow sidebar*/}
          <nav
            aria-label="Sidebar"
            className="hidden md:block md:flex-shrink-0 md:overflow-y-auto md:bg-slate-800"
          >
            <div className="relative flex w-20 flex-col space-y-3 p-3">
              {sidebarNavigation.map((item) => (
                <a
                  key={item.name}
                  href={
                    selectedReg.id && item.name === "Мнение экспертов"
                      ? `${item.href}?regId=${selectedReg.id}`
                      : item.href
                  }
                  className={classNames(
                    item.href === window.location.pathname
                      ? "bg-white text-slate-900"
                      : "text-slate-400 hover:bg-slate-700",
                    "justify-left inline-flex h-14 w-[4.25rem] flex-shrink-0 items-center rounded-l-lg pl-4"
                  )}
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </nav>

          {/* Main area */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />}></Route>
            <Route path="/poll" element={<Poll />}></Route>
            <Route path="/poll-redactor" element={<PollRedactor />}></Route>
            <Route path="/form-success" element={<FormSuccess />}></Route>
          </Routes>
        </div>
      </div>
    </>
  )
}
