import axios from "axios"
import React, { useState } from "react"
import { useDispatch, useStore } from "react-redux"
import {
  deleteRequirements,
  fetchData,
  formSubmit,
  setObjectAsMain,
  setSelectedState,
  writeRequirements,
} from "../../store/slices/regulationsSlice"
import {
  ACTIONS_BUYLIST_URL,
  REGULATION_APPROVE_API_URL,
  REQUIREMENTS_API_URL,
  SET_MAIN_REGULATION_URL,
} from "../../api/regulations"
import {
  ArrowUturnUpIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  DocumentChartBarIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/20/solid"
import { useNavigate } from "react-router-dom"
import Collapse from "@mui/material/Collapse"
import Cookies from "js-cookie"
import { NotificationManager } from "react-notifications"

export const RegReqItem = ({
  object,
  setMainState,
  handleOpen,
  handleOpenDelete,
  requirements,
  objType,
}) => {
  let dispatch = useDispatch()
  let navigate = useNavigate()
  let store = useStore().getState()
  let priceDifference = object.main
    ? undefined
    : {
        result:
          Number((store.data.regs.find((e) => e.main) || {}).price || 0) - Number(object.price),
      }
  const [expanded, setExpanded] = useState(false)
  const handleExpandEnter = () => setExpanded(true)
  const handleExpandLeave = () => setExpanded(false)

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }

  const inheritState = () => {
    let obj = { ...object }
    obj.objType = objType
    setMainState(obj)
  }

  return (
    <>
      <div className="w-full rounded-lg border border-gray-200 bg-white text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white">
        <button
          type="button"
          className={classNames(
            object.selected ? "bg-slate-50" : "bg-white",
            "relative inline-flex w-full border-0 px-4 py-2 hover:bg-gray-100 focus:z-10 focus:text-blue-700"
          )}
          onMouseEnter={handleExpandEnter}
          onMouseLeave={handleExpandLeave}
          onClick={(e) => {
            if (e.target.tagName !== "BUTTON" && e.target.tagName !== "svg") {
              dispatch(setSelectedState({ objType: objType, object: object }))
              if (objType === "regs") {
                if (!object.selected) {
                  axios
                    .get(REQUIREMENTS_API_URL(object.id), {
                      headers: { Authorization: `Token ${Cookies.get("access_token")}` },
                    })
                    .then((r) => {
                      dispatch(writeRequirements({ data: r.data.results }))
                    })
                } else {
                  dispatch(
                    deleteRequirements({
                      arr: requirements.filter(
                        (e) => e.regulation === object.id && e.status !== "Предложено"
                      ),
                    })
                  )
                }
              } else if (objType === "reqs") {
                let compareID = store.data.actions[0]
                  ? store.data.actions[0].requirement
                  : store.data.buyList[0]
                  ? store.data.buyList[0].requirement
                  : 0
                if (object.id !== compareID) {
                  axios
                    .get(ACTIONS_BUYLIST_URL(object.id), {
                      headers: { Authorization: `Token ${Cookies.get("access_token")}` },
                    })
                    .then((r) => {
                      dispatch(fetchData({ data: r.data }))
                    })
                }
              }
            }
          }}
        >
          {object.selected && objType === "regs" ? (
            <ChevronDownIcon className={"mr-4 h-6 w-6"} />
          ) : (
            objType === "regs" && <ChevronRightIcon className={"mr-4 h-6 w-6"} />
          )}

          <div
            className={classNames(
              objType === "regs" ? "min-h-[4rem] space-y-4" : "space-y-0.5",
              "flex-1"
            )}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-1">
                {object.status === "Утвержден" && objType === "regs" ? (
                  <CheckBadgeIcon className="h-[16px] w-[16px] text-green-500" />
                ) : (
                  object.status === "Не утвержден" &&
                  objType === "regs" && (
                    <CheckBadgeIcon
                      className="h-[16px] w-[16px] text-red-500"
                      onClick={() => {
                        dispatch(
                          formSubmit({
                            objType: "regs",
                            actionType: "update",
                            object: { ...object, status: "Утвержден" },
                          })
                        )
                        axios
                          .put(
                            REGULATION_APPROVE_API_URL,
                            { regId: object.id },
                            { headers: { Authorization: `Token ${Cookies.get("access_token")}` } }
                          )
                          .then((r) => NotificationManager.success("message", "Утвержден"))
                      }}
                    />
                  )
                )}
                <div className="ml-2 max-h-8 overflow-hidden text-clip text-left text-sm font-medium ">
                  {object.name}
                </div>
              </div>
              {object.type === "Издержки" ? (
                <div className="badge badge-red" label={object.type}>
                  Издержки
                </div>
              ) : (
                object.type === "Выгоды" && (
                  <div className="badge badge-green" label={object.type}>
                    Выгоды
                  </div>
                )
              )}
            </div>
            {objType === "regs" && (
              <>
                <div className="flex items-center justify-between">
                  <div className="badge bagde-red flex space-x-1">
                    <BanknotesIcon className="w-4 text-slate-500" label={object.price} />
                    <div className="text-sm">{object.price}</div>
                  </div>
                  {priceDifference && (
                    <div className="badge bagde-red flex space-x-1">
                      <BanknotesIcon
                        className={classNames(
                          priceDifference.result < 0 ? "text-red-700" : "text-green-700",
                          "w-4"
                        )}
                        label={priceDifference.result}
                      />
                      <div
                        className={classNames(
                          priceDifference.result < 0 ? "text-red-700" : "text-green-700",
                          "text-sm"
                        )}
                      >
                        {priceDifference.result}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            {object.calc_price && (
              <div className="badge bagde-red flex space-x-1">
                <BanknotesIcon className="w-4 text-slate-500" label={object.calc_price} />
                <div className="text-sm">{object.calc_price}</div>
              </div>
            )}
          </div>
          <Collapse in={expanded} orientation={"horizontal"} timeout="auto" unmountOnExit>
            <div className="my-0 flex-1 space-y-0.5 py-0">
              {objType === "regs" && object.main && (
                <div
                  className={""}
                  onClick={() => {
                    navigate(`/poll-redactor/?regId=${object.id}`)
                  }}
                >
                  <DocumentChartBarIcon className={"h-[16px] w-[16px] text-yellow-500"} />
                </div>
              )}
              {objType === "regs" && !object.main && (
                <div
                  className={""}
                  onClick={() => {
                    dispatch(setObjectAsMain({ objectID: object.id }))
                    axios
                      .post(
                        SET_MAIN_REGULATION_URL,
                        { regId: object.id },
                        { headers: { Authorization: `Token ${Cookies.get("access_token")}` } }
                      )
                      .then((r) => NotificationManager.success("message", "Title here"))
                  }}
                >
                  <ArrowUturnUpIcon className={"h-[16px] w-[16px] text-yellow-500"} />
                </div>
              )}
              <div
                className={""}
                onClick={() => {
                  inheritState()
                  handleOpen()
                }}
              >
                <PencilSquareIcon className={"h-[16px] w-[16px] text-blue-600"} />
              </div>
              <div
                className={""}
                onClick={() => {
                  inheritState()
                  handleOpenDelete()
                }}
              >
                <TrashIcon className={"h-[16px] w-[16px] text-red-600"} />
              </div>
            </div>
          </Collapse>
        </button>
      </div>

      {object.selected && objType === "regs" && (
        <Collapse in={Boolean(object.selected)} timeout="auto" unmountOnExit className="ml-6 ">
          <div className="flex-1 space-y-1 border-0 border-l-2 border-solid border-slate-200 pl-4">
            {requirements
              .filter((e) => e.regulation === object.id)
              .map((item, index) => (
                <RegReqItem
                  key={index}
                  object={item}
                  setMainState={setMainState}
                  handleOpen={handleOpen}
                  handleOpenDelete={handleOpenDelete}
                  requirements={undefined}
                  objType="reqs"
                />
              ))}
          </div>
        </Collapse>
      )}
    </>
  )
}
