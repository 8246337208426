import PropTypes from "prop-types"
import { useMemo } from "react" // @mui
import { CssBaseline } from "@mui/material"
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles"

// import palette from "./palette"
import typography from "./typography"
// import shadows from "./shadows"
import GlobalStyles from "./globalStyles"
// import customShadows from "./customShadows"

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
}
export default function ThemeProvider({ children }) {
  const themeOptions = useMemo(
    () => ({
      typography,
    }),
    []
  )

  const theme = createTheme(themeOptions)
  // theme.components = componentsOverride(theme)

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}
