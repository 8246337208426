import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material"
import { deleteObject } from "../../store/actions"
import { useDispatch } from "react-redux"

export const DeleteObjectModal = ({ object, isOpenDelete, handleCloseDelete }) => {
  let dispatch = useDispatch()

  return (
    <Dialog open={isOpenDelete} onClose={handleCloseDelete}>
      <DialogTitle variant="h6" mb={2}>
        Вы уверены, что хотите удалить {object.name}?
      </DialogTitle>
      <DialogActions>
        <Button
          variant="contained"
          color="warning"
          onClick={() => {
            handleCloseDelete()
          }}
        >
          Нет
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            if (object.id) {
              deleteObject(dispatch, object.objType, object)
            }
            handleCloseDelete()
          }}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  )
}
