import { useState } from "react"
import { useDispatch } from "react-redux"
import { formSubmit } from "../../store/slices/regulationsSlice"
import { CompiledInput } from "./CompiledInput"
import { Draggable } from "react-beautiful-dnd"

export const Question = ({ itemObj, index }) => {
  let dispatch = useDispatch()
  const [formInput, setFormInput] = useState(itemObj)

  const inputChange = (e) => {
    let obj = { ...formInput }
    if (e.target.name === "name" && obj[obj.name]) {
      delete obj[obj.name]
    }
    obj[e.target.name] = e.target.value
    setFormInput(obj)
    dispatch(formSubmit({ objType: "questions", actionType: "update", object: obj }))
  }

  return (
    <Draggable draggableId={itemObj.id.toString()} index={index}>
      {/* DEBUG: KEEP BORDER */}
      {(provided) => (
        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <div className="hover:-p-0.5 mx-2 min-h-[6rem] rounded border-[1px] border-solid border-slate-200 bg-white p-4 hover:p-3.5 hover:shadow">
            <CompiledInput settings={itemObj} onChange={inputChange} redactor={true} />
          </div>
        </div>
      )}
    </Draggable>
  )
}
