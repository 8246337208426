export function remToPx(value) {
  return Math.round(parseFloat(value) * 16)
}

export function pxToRem(value) {
  return `${value / 16}rem`
}

export function responsiveFontSizes({ sm, md, lg }) {
  return {
    "@media (min-width:600px)": {
      fontSize: pxToRem(sm),
    },
    "@media (min-width:900px)": {
      fontSize: pxToRem(md),
    },
    "@media (min-width:1200px)": {
      fontSize: pxToRem(lg),
    },
  }
}

const FONT_PRIMARY = "GolosUIWebRegular, sans-serif" // Local Font
const FONT_MEDIUM = "GolosUIWebMedium, sans-serif" // Local Font
const FONT_BOLD = "GolosUIWebBold, sans-serif" // Local Font
const FONT_SECONDARY = "Days One, sans-serif" // Local Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: "normal",
  fontWeightMedium: "normal",
  fontWeightBold: "normal",
  subtitle1: {
    fontFamily: FONT_BOLD,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontFamily: FONT_MEDIUM,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  caption: {
    fontFamily: FONT_MEDIUM,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontFamily: FONT_MEDIUM,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: "uppercase",
  },
  button: {
    fontFamily: FONT_BOLD,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: "capitalize",
  },
}

export default typography
